<template lang="pug">

.page.help-page.content-styles.content-container
  
  h1.page-title MLS App Explained 
  p MLS stands for Multiple Listing Service. This service contains a collection of real estate listings available for sale, which is what this app delivers to you. 
  p You can access such listings by using the map and filters to explore available real properties in this app. To see the most listings, keep the map zoomed out and set use liberal settings on the filters. 
  
  h2 App Structure
  
  h3 Top Bar 
  p The top bar contains a "Home" button, an information bar, and the "Account" button. 
  ul 
    li Home: return to the MLS with default filters &amp; map view.
    li Info Bar: occasionally displays text information.
    li Account: allows you to access your account in the user area.
  h3 Main Body 
  p The main body contains a Map and a Listings section. 
  p Depending on your screen size features may be added or removed. 
    | Some features will remain available by pressing Settings or Filters, 
    | which is available in the Bottom Bar. 
  h3 Bottom Bar 
  p The bottom bar contains a Settings button, status bar, and Filters button. 
  ul 
    li Settings: change certain features or access different areas. 
    li Status Bar: usually contains information about listings in view. 
    li Filters: allows you to change listing parameters such as bedrooms, price, etc.
  
  h2 App Features 

  h3 Save/Favorite Listing
  p You can save (or favorite) a listing by clicking the Heart icon in the top left of each listing. 
    | This will require an account to use. 
  
  h3 User Account 
  p You can create a user account by signing up via Google, Facebook, or Email. 
    | This will retreive and store basic information (such as your name) and 
    | add you as a user in our MLS App. Please change any information you deem incorrect 
    | in the Account Area. 
  p You can 
  ul 
    li Edit your contact information. 
    li View and remove stored listings. 
    li View/download your paperwork if you are a client. 
  p To logout, simply click the Log Out button at the bottom of the Account Area. 
  
  h3 Layout 
  p You can alternate between a Vertical and Horizontal layout. 
    | A pre-determined layout will be selected for you depending on your screen size. 
    | To change your layout, click the Settings icon on the left side of the Bottom Bar. 
    | Not all views will support different layouts. 

  h3 Filters
  p Filters allow you to modify the listings available to you by 
    | selecting different parameters (such as bedrooms, price, etc.). 
    | To change filters click the Filter icon on the right side of the Bottom Bar. 
  p Once you are content with your filters click Update Filters. This will 
    | change the listings available in the Map section. To view more listings, zoom out. 
  p Depending on your view size additional features may be added or removed 
    | from this section (such as sorting options). 

  h3 Sorting 
  p You can sort listings by selecting the method you want from the dropdown menu. 
    | This menu will be available in either the Listings section of the Main Body, 
    | or in the Filters section depending on screen size. 

  br 
  br 
  a(href='/')
    button.button.button-standard Return to MLS App

</template>

<script>

export default {
  name: 'Help',
  components: {
  },
  data(){
    return {
    }
  },
  mounted(){
    

  },
  methods: {


  }

}

</script>

<style scoped>

@import url(https://alexsellsrichmond.com/files/css/core.css);

@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css);

@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css);

</style>